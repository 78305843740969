<template>
  <div class="d-flex">
    <SidebarLeft />
    <div class="pages">
      <div class="container-fluid">
        <div class="page-title">
          <h5>Editar Material Digital</h5> 
          <span>{{ $store.state.library.name }}</span>
        </div>
        <div class="row mb-4">
          <div class="card" style="padding:20px">
            
            <div class="row mb-4">
              <div class="col-md-2">
                <div v-if="book.cover" class="text-center mb-2">
                  <img :src="book.cover" alt="" style="width:120px; height:120px; object-fit:cover; border-radius:12px;">
                </div>
                <div v-else style="height:100px; width:120px; background-color: rgb(204, 204, 193); border-radius: 12px;">
                  <span class="material-icons-round" style="position:absolute; left:65px; top:55px">image</span>
                </div>
              </div>
              <div class="col-md-10 mb-3">
                <label for="cover">Arquivo publicado</label>
                <div class="input-group">
                  <input class="form-control" v-model="book.source_url" disabled>
                  <a :href="book.source_url" target="_blank" class="btn btn-outline-primary" type="button" id="button-addon2">
                    <span class="material-icons-round mt-1">open_in_new</span>
                  </a>
                </div>
                <div v-if="book.material_type != 'url'" class="mt-3 text-secondary">
                  Você está vendo um link temporário. Quando um leitor solicita um material digital, recebe uma URL exclusiva para baixá-lo.
                </div>
              </div>
            </div>

            <form class="row">
              <div class="col-md-12 mb-3">
                <label for="title" class="text-danger">Título da Obra</label>
                <input id="title" v-model="book.title" placeholder="Título da obra" required class="form-control">
              </div>
              
              <div class="col-md-12 mb-3">
                <label for="subtitle">Subtítulo</label>
                <input id="subtitle" v-model="book.subtitle" placeholder="Subtítulo" class="form-control">
              </div>
              
              <div class="col-md-8 mb-3">
                <label for="author" class="text-danger">Autor</label>
                <input id="author" v-model="book.author" placeholder="Autor" class="form-control">
              </div>

              <div class="col-md-4 mb-3">
                <label for="cutter">Código Cutter</label>
                <input id="cutter" v-model="book.cutter" placeholder="Código Cutter" class="form-control">
              </div>
              
              <div class="col-md-12 mb-3">
                <label for="authors_others">Outros Autores</label>
                <input id="authors_others" v-model="book.authors_others" placeholder="Outros autores" class="form-control">
              </div>

              <div class="col-md-4 mb-3">
                <label for="year" class="text-danger">Ano de Publicação</label>
                <input id="year" v-model="book.year" placeholder="Ano de Publicação" class="form-control">
              </div>

              <div v-if="$store.state.library.classification_type === 'cdu'" class="col-md-4 mb-3">
                <label for="cdu_category_id" class="text-danger">Classificação CDU</label>
                <select v-model="book.cdu_category_id" id="cdu_category_id" class="form-select">
                  <option 
                    v-for="item in cduCodes" 
                    :key="item.id"
                    :value="item.id" 
                    :selected="item.cdu_category_id === book.cdu_category_id">
                    {{ `${item.code} - ${item.name}` }}
                  </option>
                </select>
              </div>

              <div v-if="$store.state.library.classification_type === 'cdd'" class="col-md-4 mb-3">
                <label for="dewey_category_id" class="text-danger">Classificação Dewey</label>
                <select id="dewey_category_id" class="form-select" v-model="book.dewey_category_id">
                  <option v-for="option in dewey" v-bind:value="option.id" v-bind:key="option.id">
                    {{ option.name }}
                  </option>
                </select>
              </div>

              <div v-if="$store.state.library.classification_type === 'cdd'" class="col-md-4 mb-3">
                <label for="dewey_centesimal">Classificação Centesimal Dewey</label>
                <input id="dewey_centesimal" v-model="book.dewey_centesimal" class="form-control">
              </div>

              <div class="col-md-4 mb-3">
                <label for="publisher" class="text-danger">Editora</label>
                <input id="publisher" v-model="book.publisher" placeholder="Editora" class="form-control">
              </div>

              <div class="col-md-4 mb-3">
                <label for="available" class="text-danger">Pode ser emprestado</label>
                <select id="available" class="form-select" v-model="book.available">
                  <option value="1">Sim</option>
                  <option value="0">Não</option>
                </select>
              </div>

              <div class="col-md-4 mb-3">
                <label for="total_pages">Páginas</label>
                <input id="total_pages" v-model="book.total_pages" class="form-control">
              </div>

              <div class="col-md-4 mb-3">
                <label for="edition">Edição</label>
                <input id="edition" v-model="book.edition" class="form-control">
              </div>

              <div class="col-md-4 mb-3">
                <label for="language">Idioma</label>
                <input id="language" v-model="book.language" class="form-control">
              </div>

              <div class="col-md-12 mb-3">
                <label for="description">Resumo</label>
                <textarea id="description" v-model="book.resume" rows="2" class="form-control"></textarea>
              </div>

              <div class="col-md-12 mb-3">
                <label for="internal_notes">Anotações Internas</label>
                <textarea id="internal_notes" v-model="book.internal_notes" rows="2" class="form-control"></textarea>
              </div>

              <div class="col-md-12 mt-2">
                <button type="button" @click="$router.push('/acervo')" class="btn btn-outline-secondary" style="margin-right:15px">Cancelar</button>
                <button type="button" @click="update" class="btn btn-primary">Atualizar</button>
              </div>
          </form>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import Env from '../../../../env.js'
  import SidebarLeft from '@/components/SidebarLeft.vue'

  export default{
    name: 'AcervoEditarComponent',
    components: {
      SidebarLeft
    },
    data() {
      return {
        book: {
          cover: '',
          cdu_category_id: null,
          cutter: null,
          available: null,
        },
        dewey: [],
        cduCodes: [],
        coverUploaded: false,
      }
    },

    created() {
      this.dewey = Env.Dewey;
      this.fetchBook();
      this.fetchCduCategories();
    },

    methods: {

      async fetchBook() {
        this.$vs.loading()
        
        try {
          const response = await this.$axios.get(`/libraries/ebooks/${this.$route.params.id}`)
          this.book = response.data;
          if (this.book.available === true) {
            this.book.available = 1;
          }
          if (this.book.available === false) {
            this.book.available = 0;
          }
          this.coverUploaded = (response.data.cover != null) ? true : false;
          
          this.$vs.loading.close();

        }catch(error) {
          this.$vs.loading.close();
          this.notificationError({
            message: error.response.data.message
          });
        }
      },

      async fetchCduCategories() {
        this.$vs.loading()
        
        try {
          const response = await this.$axios.get(`/libraries/cdu-categories`);
          this.cduCodes = response.data;
          this.$vs.loading.close();

        } catch (error) {
          console.log(error);
          this.notificationError({ message: error.response.data.message });
        }
      },

      async update() {
        this.$vs.loading();
        
        try {
          const response = await this.$axios.put(`/libraries/ebooks/${this.$route.params.id}`, this.book);
          console.log(response.data.data.message);
          this.notificationSuccess(response.data.data.message)
          this.$vs.loading.close();

        } catch(error) {
          this.notificationError({ message: error.response.data.message });
          this.$vs.loading.close();
        }
      },

      notificationSuccess({ title = 'Sucesso!', message }) {
         this.$vs.notify({
            title: title,
            text: message,
            color: 'success',
            position: 'top-right'
         });
      },

      notificationError({ title = 'Ops!', message }) {
         this.$vs.notify({
            title: title,
            text: message,
            color: 'danger',
            position: 'top-right'
         });
      }
    },
  }
</script>

<style lang="scss" scoped>
</style>
