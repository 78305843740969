<template>
   <div class="d-md-flex">
      <SidebarLeft />
      <div class="pages">
         <div class="container-fluid">
            
            <div class="page-title">
               <h5>Ficha do Material</h5> 
               <span>{{ $store.state.library.name }}</span>
            </div>
            
            <div class="row">
               <div class="col-md-8 mb-4">

                  <div v-if="book.internal_notes" class="card fs-6 py-4 mb-4" style="padding:20px">
                     <div class="">
                        <div class="text-muted mb-2">ANOTAÇÕES INTERNAS:</div>
                        <div class="text-uppercase">{{ book.internal_notes }}</div>
                     </div>
                  </div>
                  <div class="card fs-6 py-4" style="padding:20px">

                     <div class="row">
                        <div class="col-md-8">
                           <div class="fs-5 fw-bolder mb-3">
                              <span class="text-muted me-1">TÍTULO: </span>
                              <span class="text-uppercase">{{ book.title }}</span>
                           </div>
                           <div v-if="book.subtitle" class="mb-3">
                              <span class="text-muted me-1">SUBTÍTULO: </span>
                              <span class="text-uppercase">{{ book.subtitle }}</span>
                           </div>
                           <div class="mb-3">
                              <span class="text-muted me-1">AUTOR:</span>
                              <span class="text-uppercase">{{ book.author }}</span>
                           </div>
                           <div v-if="book.authors_others != null" class="mb-3">
                              <span class="text-muted me-1">OUTROS AUTORES:</span>
                              <span class="text-uppercase">{{ book.authors_others }}</span>
                           </div>
                           <div class="mb-3">
                              <span class="text-muted me-1">EDITORA:</span>
                              <span class="text-uppercase">{{ book.publisher }}</span>
                           </div>
                           <div class="mb-3">
                              <span class="text-muted me-1">ANO:</span>
                              <span class="text-uppercase">{{ book.year }}</span>
                           </div>
                           <div class="mb-3">
                              <span class="text-muted me-1">DISPONÍVEL PARA EMPRÉSTIMO:</span>
                              <span class="rounded-pill" :class="book.available ? 'badge bg-success' : 'badge bg-danger'">
                                 <span class="px-2">{{ book.available ? 'Disponível' : 'Indisponível' }}</span>
                              </span>
                           </div>
                           <div class="mb-3">
                              <span class="text-muted me-1">NÚMERO DE CHAMADA:</span>
                              <span class="text-uppercase">{{ book.call_number }}</span>
                           </div>      
                        </div>
                        <div class="col-md-4">
                           <div v-if="book.cover && book.cover.length > 10" class="mb-3 text-center border rounded">
                              <img :src="book.cover" alt="" class="img-fluid" style="width:180px">
                           </div>
                           <div v-else class="mb-3 text-center border rounded bg-light py-5 justify-content-center">
                              <p class="text-muted">Sem capa</p>
                           </div>
                        </div>      
                     </div>

                     <div v-if="book.dewey_category_id" class="mb-3">
                        <span class="text-muted me-1">CLASSIFICAÇÃO DEWEY:</span>
                        <span class="text-uppercase">{{ book.dewey_centesimal ? book.dewey_centesimal : (book.dewey_category_id - 1) + '00' }}</span>
                     </div>

                     <div v-if="book.cdu_code" class="mb-3">
                        <span class="text-muted me-1">CÓDIGO CDU:</span>
                        <span class="text-uppercase">{{ book.cdu_code }}</span>
                     </div>

                     <div v-if="book.classification_id" class="mb-3">
                        <span class="text-muted me-1">CLASSIFICAÇÃO DA BIBLIOTECA:</span>
                        <span class="text-uppercase">{{ `${book.classification?.code} - ${book.classification?.name}` }}</span>
                     </div>
                     
                     <div class="mb-3">
                        <span class="text-muted me-1">DATA CADASTRO:</span>
                        <span class="text-uppercase">{{ book.created_at?.split(' ')[0].split('-').reverse().join('/') + ' ' + book.created_at?.split(' ')[1] }}</span>
                     </div>
                     
                     <div class="mb-3">
                        <span class="text-muted me-1">CÓDIGO CUTTER:</span>
                        <span class="text-uppercase">{{ book.cutter }}</span>
                     </div>
                     
                     <div class="mb-3">
                        <span class="text-muted me-1">DESCRIÇÃO FÍSICA:</span>
                        <span class="text-uppercase">{{ book.description }}</span>
                     </div>
                     
                     <div class="mb-3">
                        <span class="text-muted me-1">EDIÇÃO:</span>
                        <span class="text-uppercase">{{ book.edition }}</span>
                     </div>
                     
                     <div class="mb-3">
                        <span class="text-muted me-1">CÓDIGO ISBN/ISSN:</span>
                        <span class="text-uppercase">{{ book.isbn_code ? book.isbn_code : 'Não informado' }}</span>
                     </div>
                     
                     <div class="mb-3">
                        <span class="text-muted me-1">IDIOMA:</span>
                        <span class="text-uppercase">{{ book.language }}</span>
                     </div>
                     
                     <div class="mb-3">
                        <span class="text-muted me-1">TIPO DE MATERIAL:</span>
                        <span class="text-uppercase">{{ materialTypes.find(t => t.code == book.material_type)?.name ?? book.material_type }}</span>
                     </div>
                     <div class="mb-3">
                        <span class="text-muted me-1">ORIGEM:</span>
                        <span class="text-uppercase">{{ book.origin }}</span>
                     </div>
                     <div v-if="book.resume" class="mb-3">
                        <span class="text-muted me-1">RESUMO (ATÉ 240 CARACTERES):</span>
                        <span class="text-uppercase">{{ book.resume }}</span>
                     </div>
                     <div v-if="book.source_url" class="mb-3">
                        <span class="text-muted me-1">URL DO CONTEÚDO:</span>
                        <a :href="book.source_url" target="_blank" class="text-uppercase">abrir em nova aba (link temporário)</a>
                     </div>
                     <div class="mb-3">
                        <span class="text-muted me-1">TOTAL DE PÁGINAS:</span>
                        <span class="text-uppercase">{{ book.total_pages }}</span>
                     </div>
                     <div class="mb-4">
                        <span class="text-muted me-1">VOLUME:</span>
                        <span class="text-uppercase">{{ book.volume ? book.volume : 'Não informado' }}</span>
                     </div>
                  </div>
               </div>
               
               <div class="col-md-4 mb-4">
                  <div class="card text-center py-4 px-3 mb-4">
                     <h3 class="fs-6 mb-3">Ações</h3>
                     <div class="d-flex justify-content-center gap-2">
                        <router-link to="/acervo" class="btn btn-secondary btn-rounded px-4">Voltar</router-link>
                        <router-link v-if="['ebook', 'ejournal', 'url'].includes(book.material_type)" :to="`/acervo/digital/${book.id}/editar`" class="btn btn-primary btn-rounded px-4">Editar</router-link>
                        <router-link v-else :to="`/acervo/${book.id}`" class="btn btn-primary btn-rounded px-4">Editar</router-link>
                        <button data-bs-toggle="modal" data-bs-target="#modalDelete" class="btn btn-danger btn-rounded px-4">Excluir</button>
                     </div>
                  </div>
                  <div class="mb-4">
                     <div class="card" style="padding:20px">
                        <div class="d-flex justify-content-between mb-3">
                           <div>
                              <h3 class="fs-6 mb-3">Exemplares ({{ book.copies }})</h3>
                           </div>
                           <div>
                              <button data-bs-toggle="modal" 
                                 data-bs-target="#storeCopy" 
                                 class="btn btn-sm btn-primary btn-rounded py-0 px-3">
                                 adicionar
                              </button>
                           </div>
                        </div>
                        <div class="table-responsive">
                           <table class="table table-striped">
                              <tbody>
                                 <tr v-for="copy in book.list_copies" :key="copy.id">
                                    <td>Exemplar {{ copy.copy_number }}</td>
                                    <td>
                                       <span :class="copy.is_active ? 'badge rounded-pill bg-success' : 'badge rounded-pill bg-danger'">
                                          {{  copy.is_active ? 'Ativo' : 'Inativo' }}
                                       </span>
                                    </td>
                                    <td>
                                       <span v-if="copy.is_active" :class="copy.is_loaned ? 'badge rounded-pill bg-secondary' : 'badge rounded-pill bg-success'">
                                          {{ copy.is_loaned ? 'Emprestado' : 'Disponível' }}
                                       </span>
                                    </td>
                                    <td>
                                       <router-link :to="`/copias/${copy.id}/editar`" class="btn btn-secondary btn-rounded py-0 px-3 btn-sm">editar</router-link>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
                  <div class="mb-4">
                     <div class="card" style="padding:20px">
                        <div class="d-flex justify-content-between mb-3">
                           <div>
                              <h3 class="fs-6 mb-3">Coleções</h3>
                           </div>
                           <div>
                              <button data-bs-toggle="modal" 
                                 data-bs-target="#addToCollection" 
                                 class="btn btn-sm btn-primary btn-rounded py-0 px-3">
                                 adicionar
                              </button>
                           </div>
                        </div>
                        <div v-if="book.collections.length > 0" class="table-responsive">
                           <table class="table table-striped">
                              <tbody>
                                 <tr v-for="collection in book.collections" :key="collection.id">
                                    <td>Coleção {{ collection.collection_id }}</td>
                                    <td>
                                       <div class="d-flex justify-content-end gap-3">
                                          <router-link :to="`/colecoes/${collection.collection_id}`" class="btn btn-secondary btn-rounded py-0 px-3 btn-sm">ver</router-link>
                                          <button data-bs-toggle="modal" 
                                             data-bs-target="#modalItemCollectionDelete" 
                                             @click="setItemCollection(collection.id)"
                                             class="btn btn-danger btn-rounded py-0 px-3 btn-sm">
                                             remover
                                          </button>
                                       </div>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                        <div v-else>
                           <p>Este material não está em nenhuma coleção.</p>
                        </div>
                     </div>
                  </div>
                  <div class="mb-4">
                     <div class="card" style="padding:20px">
                        <h3 class="fs-6 mb-3">Empréstimos recentes</h3>
                        <div class="table-responsive text-muted">
                           Em breve esta informação estará disponível para você.
                        </div>
                     </div>
                  </div>
               </div>

            </div>

         </div>
      </div>

      <!-- Modal Copy -->
      <div class="modal fade" id="storeCopy" data-bs-keyboard="false" tabindex="-1" aria-labelledby="storeCopyLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="storeCopyLabel">Cadastrar um exemplar</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <p class="mb-2">Deseja cadastrar um exemplar para <span class="fw-bolder">{{ book.title }}</span>?</p>
                  <p>Vamos atribuir um novo número de identificação automaticamente.</p>
               </div>
               <div class="modal-footer">
                  <button 
                     type="button" 
                     class="btn btn-secondary" 
                     data-bs-dismiss="modal">
                     Fechar
                  </button>
                  <button 
                     v-on:click="storeCopy"
                     data-bs-dismiss="modal"
                     type="button" class="btn btn-primary">
                     Cadastrar
                  </button>
               </div>
            </div>
         </div>
      </div>

      <!-- Modal Colecoes -->
      <div class="modal fade" id="addToCollection" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addToCollectionLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="addToCollectionLabel">Adicionar a uma coleção</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  
                  <p>Com coleções você cria listas de livros com total liberdade e
                     ainda pode compartilhar nas redes sociais.
                  </p>

                  <div class="mb-4">
                     <select class="form-select" v-model="storeCollectionForm.collection_id">
                        <option value="" selected>Selecione a coleção</option>
                        <option 
                           v-for="collection in collections" 
                           v-bind:key="collection.id"
                           :value="collection.id">
                           {{ collection.name }}
                        </option>
                     </select>
                  </div>

                  <div class="mb-2">
                     <span 
                        @click="$router.push('/colecoes/criar')" 
                        data-bs-dismiss="modal"
                        class="text-primary"
                        role="button"
                        style="text-decoration: none;">
                        Criar coleção
                     </span>
                     <span class="mx-1 text-secondary">ou</span>
                     <span 
                        @click="$router.push('/colecoes')" 
                        data-bs-dismiss="modal"
                        class="text-primary"
                        role="button"
                        style="text-decoration: none;">
                        Ver coleções
                     </span>
                  </div>
               </div>
               <div class="modal-footer">
                  <button 
                     type="button" 
                     class="btn btn-secondary" 
                     data-bs-dismiss="modal">
                     Fechar
                  </button>
                  <button 
                     v-on:click="storeToCollection"
                     data-bs-dismiss="modal"
                     type="button" class="btn btn-primary">
                     Adicionar
                  </button>
               </div>
            </div>
         </div>
      </div>

      <!-- Modal Book Delete -->
      <div class="modal fade" id="modalDelete" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalDelete" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered">
         <div class="modal-content">
            <div class="modal-header">
               <h5 class="modal-title" id="modalDelete">Excluir Material</h5>
               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
               Confirma a exclusão de {{ book.title }}?
            </div>
            <div class="modal-footer">
               <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               <button 
               v-on:click="confirmModalDelete()"
               data-bs-dismiss="modal"
               type="button" class="btn btn-danger">Excluir</button>
            </div>
         </div>
         </div>
      </div>

      <!-- Modal Item Collection Delete -->
      <div class="modal fade" id="modalItemCollectionDelete" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalItemCollectionDelete" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered">
         <div class="modal-content">
            <div class="modal-header">
               <h5 class="modal-title" id="modalItemCollectionDelete">Remover da Coleção</h5>
               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
               Confirma a remoção de {{ book.title }} desta coleção?
            </div>
            <div class="modal-footer">
               <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               <button 
               v-on:click="confirmItemCollectionDelete()"
               data-bs-dismiss="modal"
               type="button" class="btn btn-danger">Excluir</button>
            </div>
         </div>
         </div>
      </div>

   </div>
</template>

<script>
import SidebarLeft from '../../components/SidebarLeft'
export default {
   name: 'AcervoFichaPage',
   components: {
      SidebarLeft
   },
   mounted() {
      this.getBook();
      this.fetchCollections();
   },
   computed: {
     materialTypes() {
       return this.$store.getters.getAllMaterialTypes;
     } 
   },
   data() {
      return {
         book: {
            title: '',
            author: '',
            created_at: '',
            dewey_category_id: '',
            collections: [],
            list_copies: [],
         },
         collections: [],
         itemCollectionSelected: null,
         bookDelete: {
            id: '',
            title: '',
            index: '',
            type: ''
         },
         storeCollectionForm: {
            book_id: '',
            collection_id: '',
         },
      }
   },
   methods: {
      async getBook() {
         this.$vs.loading();
         let url = `/libraries/books/${this.$route.params.id}`;
         if (this.$route.query.type == 'digital') {
            url = `/libraries/ebooks/${this.$route.params.id}`;
         }
         try {
            const response = await this.$axios.get(url)
            this.book = response.data;
            this.coverUploaded = (response.data.cover != null) ? true : false;

         } catch (error) {
            this.$vs.notify({
               title: 'Erro',
               text: error.response.data?.data.message,
               color: 'danger',
               position: 'top-right'
            })
         }
         this.$vs.loading.close();
      },

      async confirmModalDelete(){
         this.$vs.loading();
         let url = `/libraries/books/${this.book.id}`;
         if (this.$route.query.type == 'digital') {
            url = `/libraries/ebooks/${this.book.id}`
         }

         try {
            await this.$axios.delete(url);
            await this.notificationSuccess('Material excluído com sucesso');
            setTimeout(() => {
               this.$router.push('/acervo');
            }, 2000);
            this.$vs.loading.close();
            
         } catch(err) {
            this.notificationError(err.response?.data.message ?? 'Erro ao excluir o material');
            this.$vs.loading.close();
         }
      },

      setItemCollection(id) {
         this.itemCollectionSelected = id;
      },

      async confirmItemCollectionDelete() {
         this.$vs.loading();
         try {
            const response = await this.$axios.delete(`/libraries/collections/books/${this.itemCollectionSelected}`);
            this.book.collections = this.book.collections.filter(collection => collection.id != this.itemCollectionSelected);
            this.notificationSuccess(response.data.data.message);

         } catch(err) {
            this.notificationError(err.response?.data.data.message ?? 'Erro ao remover o material da coleção');
         } 
      },

      async storeCopy() {
         this.$vs.loading();
         try {
            const response = await this.$axios.post(`/libraries/copies`, { book_id: this.book.id });
            this.book.copies += 1;
            this.book.list_copies.push(response.data);
            this.notificationSuccess('Cadastrado com sucesso');
         } catch(err) {
            this.notificationError(err.response?.data.message ?? 'Erro ao cadastrar exemplar');
         }
         this.$vs.loading.close();
      },

      async storeToCollection() {
         this.$vs.loading();
         try {
            this.storeCollectionForm.book_id = this.book.id;
            const response = await this.$axios.post('/libraries/collections/book-add', this.storeCollectionForm);
            this.notificationSuccess(response.data.data.message);
            await this.getBook();
         } catch (error) {
            this.notificationError(error.response.data.data.message ?? 'Não foi possivel adicionar à coleção');
         }
         this.$vs.loading.close();
      },

      async fetchCollections() {
         this.$vs.loading();
         const response = await this.$axios.get(`/libraries/collections`);
         try {
            this.collections = response.data;

         } catch(err) {
            console.log('Deu rum')
         }
         this.$vs.loading.close();
      },

      notificationSuccess(message) {
         return this.$vs.notify({
            title: 'Sucesso',
            text: message,
            color:'success',
            position:'top-right'
         });
      },

      notificationError(message) {
         return this.$vs.notify({
            title: 'Ops',
            text: message,
            color:'danger',
            position:'top-right'
         });
      }, 
   },
}
</script>

<style scoped>
.rounded-3 {
   border-radius: 1.3rem;
} 
.border-1 {
   border: 1px solid #ccc;
}
</style>